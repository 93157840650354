@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@mixin scrollbars(
  $size,
  $foreground-color,
  $hover-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}


.bounty_thewiewerwindow_mobile {
    background-color: #ffffff;
    position: relative;
    flex-direction: column;
    overflow: hidden;
      display: grid;
 
    @media  (orientation: portrait) {
       display: block;
    }
      @media (orientation: landscape) {
        grid-template-columns: 60px 1fr;
      }
   


}

.bounty_rendercanvas_mobile {
 
  width: 100% !important;
  height: 100% !important;
  display: inline-block;
  background-color: #464646;
  outline: none;
  overflow: hidden;
  touch-action: none;
  position: relative;
    grid-row: 1;
    grid-column: 2;

}

.bounty_nav_container_mobile {

  @media  (orientation: portrait) {
    display: none !important;
  }
  @media (orientation: landscape) {
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
    grid-row: 1;
    flex-direction: column;
    padding: unset;
    border-top: unset;
    border-bottom: unset;
    height:100%;
    width: 60px;
    position: fixed;
    grid-row: 1;
    z-index: 1;
    display: flex;
  }
}
.bounty_nav_controls_mobile {
    
    @media (orientation: landscape) {
  display: flex;

  align-items: center;
  height: 100%;
  justify-content: flex-start;

  cursor: pointer;
    flex-direction: column;
    display: none;
    }
}
.bounty_nav_controls_icon_mobile {
    display: none;
}


.bounty_nav_views_mobile {
    @media (orientation: landscape) {
  display: flex;

  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;



    flex-direction: column;
    margin-inline-start: unset;
    padding-bottom: 25px;
    }
}
.bounty_nav_views_icon_mobile {
    @media (orientation: landscape) {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
display: grid;
  justify-items: center;


  height: 100%;
 

    margin-bottom: 8px;
    width: 50px;
    &:hover {
      .bounty_tooltip_content {
     display: none;
      }
  }
}
}
.bounty_nav_views_icon_active_mobile {
    @media (orientation: landscape) {
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
      stroke: #2e2e2f;
    }
  }

    border-bottom: unset;
    border-left: 4px solid #00c7b1;
    }
}
.bounty_nva_options_mobile {
    @media (orientation: landscape) {
  display: flex;


  align-items: center;

  justify-content: center;
  cursor: pointer;

    flex-direction: column;
    margin-inline-end: unset;
    height: unset;
    }
}

.bounty_nva_options_icon_mobile {
    @media (orientation: landscape) {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  height: 100%;


  justify-items: center;
  text-align: center;

  align-items: center;
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 1s;
      visibility: visible;
      font-weight: 500;
    }
  }


    



    display: flex;

    
    
   
    flex-direction: column;
    justify-content: flex-end;
    margin-left: unset;
    border-left: unset;
    margin-inline-end: unset;
    margin-bottom: 16px;
    width: 50px;
    &:hover {
      .bounty_tooltip_content {
     display: none;
      }
    }
}
}


.bounty_rotate_active_mobile {
    @media (orientation: landscape) {
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }
  
    border-bottom: unset;
    border-left: 4px solid #00c7b1;
  
}
}
.bounty_pan_active_mobile {
    @media (orientation: landscape) {
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }

    border-bottom: unset;
    border-left: 4px solid #00c7b1;
}
}

.bounty_options_active_mobile {
    @media (orientation: landscape) {
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      stroke: #2e2e2f;
    }
  }

    border-bottom: unset;
    border-left: 4px solid #00c7b1;
    }
}
.bounty_ipr_active_mobile {
    @media (orientation: landscape) {
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }

    border-bottom: unset;
    border-left: 4px solid #00c7b1;
}
}




.bounty_play_pause_container_mobile {
   

        display: flex;
        padding-left: 16px;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;
        .bounty_play_puase_prime_color_svg {
          fill: #44e2d0;
        }
        div:active {
          svg {
            .bounty_prime_color_svg {
              fill: #292d32;
            }
          }
        }
      bottom: 0%;
      left: 25%;
    
}
.bounty_play_pause_container_big_steps_mobile {


  display: none;
}

.bounty_step_div_mobile {
    display: none;
}

.bounty_nav_icon_full_screen_mobile {
    @media (orientation: portrait) {display: none;}
  @media  (orientation: landscape) {
    position: absolute;
    display: block;
    width: 50px;
    right: 20px;
    z-index: 9;
    @supports (-webkit-touch-callout: none) {
      display: none;
    }
    svg {
      .bounty_prime_color_svg {
        fill: #FFFFFF;
      }
  }
}
}
.bounty_divider_mobile {
    display: none;
}

.bounty_nav_views_icon_text_mobile {
    display: none;
}
.bounty_rotate_phone_mobile {
  @media (orientation: landscape) {display: none;}
  @media (orientation: portrait) {
    top: 0;
  display: flex;
  width: 100%;
  z-index: 1;
  background-color: #000000;
  align-items: center;
  position: fixed;
  div {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 12px;
    margin: unset !important;
    span {
      font-weight: normal;
      padding-right: 8px;
    }
  }
}
}

.bounty_steps_container_mobile {

    margin: auto;
  
    overflow-x: hidden;
    display: flex;


    overflow-y: hidden;
    justify-self: center;
    background-color: #ffffff;
    @media (orientation: landscape) {
      border-radius: 5px 5px 0px 0px;
      position: fixed;
      bottom: 0%;
      width: unset;
      height: 40px;
      padding: 4px;
      left: 25%;
      right: 25%;
      width: fit-content;
      padding-bottom: env(safe-area-inset-bottom);
    }
    @media (orientation: portrait) {
      border-radius: 5px 5px 0px 0px;
      position: fixed;
      bottom: 0%;
      width: unset;
      height: 40px;
      padding: 4px;
      width: fit-content;
      left: 14%;
      right: 14%;
      padding-bottom: env(safe-area-inset-bottom);
  }
  }